import { Text } from '@venncity/venn-ds';

interface TextCellProps {
  text: string;
  isTextBold?: boolean;
  description?: string;
}

export const TextCell = (props: TextCellProps) => {
  const { text, description, isTextBold } = props;

  return (
    <div
      role="cell"
      className="flex h-full min-w-0 flex-col justify-center"
      data-testid="cell-text">
      <Text strong={isTextBold} ellipsis={{ tooltip: text }}>
        {text}
      </Text>
      {description && (
        <Text ellipsis={{ tooltip: description }} type="secondary">
          {description}
        </Text>
      )}
    </div>
  );
};
